<script setup lang="ts">
import { feedbackIntegration } from '@sentry/vue'

const { t } = useI18n()

const isOpen = defineModel<boolean>()

async function onReportBug() {
  const feedback = feedbackIntegration({
    autoInject: false
  })

  const form = await feedback.createForm()

  // Get the sidebar out of the way for a screenshot
  isOpen.value = false

  form.appendToDom()
  form.open()
}
</script>

<template>
  <UDashboardSlideover v-model="isOpen" :title="t('helpAndSupport')">
    <UDashboardCard
      icon=""
      :title="t('reportABug')"
      :description="t('somethingDoesntLookRight')"
      :links="[{ label: t('reportBug'), color: 'gray', leadingIcon: 'i-ion-bug-outline' }]"
      @click="onReportBug"
    />
  </UDashboardSlideover>
</template>
