<script setup lang="ts">
const { t } = useI18n()
const { $network } = useNuxtApp()

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: true
  },

  loadingText: {
    type: String,
    default: null
  },

  iconOnly: {
    type: Boolean,
    default: false
  },

  showLongLoadingText: {
    type: Boolean,
    default: true
  },

  loadingWrapperClass: {
    type: String,
    default: ''
  },

  longLoadingTextClass: {
    type: String,
    default: ''
  }
})

const slots = useSlots()

const longLoadingText = ref<string | null>(null)
const timeStart = ref<Date>(new Date())
const isConsideredTimeout = ref(false)

let timer: undefined | ReturnType<typeof setInterval>

const loadingText = computed(() => {
  if (props.loadingText) {
    return props.loadingText
  }

  return `${t('loading')}...`
})

watchEffect(() => {
  if (props.isLoading) {
    startSlowLoadingTimer()
  } else {
    stopSlowLoadingTimer()
  }
})

function startSlowLoadingTimer() {
  if (!timer) {
    timeStart.value = new Date()

    timer = setInterval(() => {
      const timeNow = new Date()

      const secondsTimeDifference = Math.abs((timeNow.getTime() - timeStart.value.getTime()) / 1000)

      if (secondsTimeDifference > 10) {
        longLoadingText.value = `${t('stillLoading')}...`
      }

      if (secondsTimeDifference > 20 || !$network.isOnline.value) {
        longLoadingText.value = t('internetConnectivityIssues')
      }

      // If their internet is offline we don't want them contacting us
      if (secondsTimeDifference > 30 && $network.isOnline.value) {
        longLoadingText.value = t('takingTooLongTryReloading')
        isConsideredTimeout.value = true
      }
    }, 1000)
  }
}

function stopSlowLoadingTimer() {
  clearInterval(timer)
  longLoadingText.value = null
}
</script>

<template>
  <div>
    <template v-if="props.isLoading">
      <slot v-if="slots.loading" name="loading" />

      <div v-else class="flex items-center select-none" data-testid="loader" :class="loadingWrapperClass">
        <Icon name="i-line-md-loading-twotone-loop" :class="{ 'mr-4': !props.iconOnly }" />

        <template v-if="!props.iconOnly">
          <p v-if="!longLoadingText">{{ loadingText }}</p>

          <p v-if="longLoadingText && showLongLoadingText" class="loading-text" :class="longLoadingTextClass">
            <slot name="longLoading">{{ longLoadingText }}</slot>
          </p>
        </template>
      </div>

      <slot v-if="isConsideredTimeout" name="timeout" />
    </template>

    <template v-else>
      <slot />
    </template>
  </div>
</template>
