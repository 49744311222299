import { isJwtExpiringInNext5Minutes } from '~/utils/auth/jwt'
import { date } from '~/utils/date'

// 10 minutes
export function generateAccessTokenLifespan() {
  const expiresFromNowDate = date.now().plus({ minutes: 10 })

  const expiresFromNowSeconds = 600

  return {
    seconds: expiresFromNowSeconds,
    secondsSinceEpoch: expiresFromNowDate.toSeconds(),
    date: expiresFromNowDate.toJSDate(),
    dateString: expiresFromNowDate.toDbFormat()
  }
}

// 5 months
export function generateRefreshTokenLifespan(restrictedExpiry: boolean = false) {
  const expiresFromNowDate = restrictedExpiry ? date.now().plus({ hours: 1 }) : date.now().plus({ months: 5 })

  const expiresFromNowSeconds = restrictedExpiry ? 3600 : 13140000

  return {
    seconds: expiresFromNowSeconds,
    secondsSinceEpoch: expiresFromNowDate.toSeconds(),
    date: expiresFromNowDate.toJSDate(),
    dateString: expiresFromNowDate.toDbFormat()
  }
}

export async function checkAccessTokenLifespan() {
  const authStore = useAuthStore()
  const authService = useAuthService()
  const { $log } = useNuxtApp()
  const router = useRouter()

  const accessToken = authStore.getAccessToken()
  const refreshToken = authStore.getRefreshToken()

  if (
    (accessToken && isJwtExpiringInNext5Minutes(accessToken) && refreshToken) ||
    (refreshToken && !accessToken)
  ) {
    $log.debug('Auth: Access token expired, refreshing')

    try {
      await authService.refreshTokens()
    } catch (error) {
      $log.debug('Failed to refresh tokens, logging out')
      authService.logout(router.currentRoute.value.fullPath)
    }
  }
}

export async function extractTokensFromResponse(response: Response) {
  const authStore = useAuthStore()
  const authService = useAuthService()
  // const { $log } = useNuxtApp()

  const accessToken = response._data?.accessToken
  const refreshToken = response._data?.refreshToken

  if (accessToken) {
    // $log.debug('Auth: Access token set from response')
    authStore.setAccessToken(accessToken)

    // Fetch profile to get user details
    try {
      await authService.fetchProfile()

      // Extract organisationId from JWT
      const payload = getPayloadFromAccessToken(accessToken)

      if (payload?.org) {
        authStore.setCurrentOrganisationId(payload.org)
      }

      if (payload?.imp) {
        authStore.setImpersonating(true)
      }
    } catch (error) {
      authService.logout()
    }
  }

  if (refreshToken) {
    // $log.debug('Auth: Refresh token set from response')
    authStore.setRefreshToken(refreshToken)
  }
}
