import { isPlainObject } from 'remeda'
import safeJsonStringify from 'safe-stringify'

import { type LogInsert } from '~/server/database/schema/log'
import type { UserWithOrganisationRoles } from '~/server/database/schema/user'
import { date } from '~/utils/date'

export const STORAGE_KEY_GUEST_ID = 'guest-id'

// The reason we use our own logging function is to perform additional
// calls like logging to Sentry
export class Log {
  list: LogInsert[] = []

  guestId: string | null | undefined

  sentry: any

  analytics: any

  errorReportingEnabled: boolean = false

  analyticsReportingEnabled: boolean = false

  saveByDefault: boolean = false

  outputToConsole: boolean = true

  cookieRef: Ref<string | null | undefined> | undefined

  constructor({
    saveByDefault = false,
    errorReportingEnabled = false,
    analyticsReportingEnabled = false,
    outputToConsole = true
  }) {
    this.saveByDefault = saveByDefault
    this.errorReportingEnabled = errorReportingEnabled
    this.analyticsReportingEnabled = analyticsReportingEnabled
    this.outputToConsole = outputToConsole
  }

  setGuestCookie(cookieRef: Ref<string | null | undefined>) {
    this.cookieRef = cookieRef
    this.guestId = cookieRef.value

    if (!this.guestId) {
      this.generateGuestId()
    }
  }

  addSentry(sentry: any) {
    this.sentry = sentry
  }

  addAnalytics(analytics: any) {
    this.analytics = analytics
  }

  getGuestId() {
    return this.guestId
  }

  resetGuestId() {
    this.generateGuestId()
  }

  generateGuestId() {
    if (!this.cookieRef) {
      return false
    }

    // A guest ID allows us to associate authed users with their previous guest actions
    this.guestId = Date.now().toString(36) + Math.random().toString(36).substring(2)

    this.cookieRef.value = this.guestId

    this.debug('Guest ID created', this.guestId)
  }

  setIdentity(userWithRoles: UserWithOrganisationRoles) {
    if (this.sentry) {
      this.sentry.setUser({
        id: userWithRoles.id,
        email: userWithRoles.email,
        username: userWithRoles.email,
        roles: userWithRoles.organisationRoles.map(role => role.role)
      })
    }

    if (this.analytics) {
      this.analytics.identify(userWithRoles.id, {
        email: userWithRoles.email,
        roles: userWithRoles.organisationRoles.map(role => role.role)
      })
    }
  }

  clearIdentity() {
    if (this.sentry) {
      this.sentry.setUser(null)
    }

    if (this.analytics) {
      this.analytics.reset()
    }
  }

  event(eventMessage: any, data?: any) {
    if (this.outputToConsole) {
      console.log(eventMessage, data)
    }

    this.addToQueue(eventMessage, data, 'event')

    if (this.analyticsReportingEnabled && this.analytics) {
      this.analytics.capture(eventMessage, data)
    }
  }

  error(message: any, error?: any, save = this.saveByDefault) {
    if (this.outputToConsole) {
      console.error(message, error)
    }

    if (this.sentry && this.errorReportingEnabled) {
      this.sentry.captureException(error || message)
    }

    if (save) {
      this.addToQueue(message, error, 'error')
    }
  }

  debug(message: any, data?: any, save = this.saveByDefault) {
    if (typeof data === 'undefined') {
      if (this.outputToConsole) {
        console.log(message)
      }
    } else if (this.outputToConsole) {
      console.log(message, data)
    }

    if (save) {
      this.addToQueue(message, data, 'debug')
    }
  }

  warn(message: any, data?: any, save = this.saveByDefault) {
    if (typeof data === 'undefined') {
      if (this.outputToConsole) {
        console.warn(message)
      }
    } else if (this.outputToConsole) {
      console.warn(message, data)
    }

    if (save) {
      this.addToQueue(message, data, 'warn')
    }
  }

  addToQueue(message: any, data: any, level: string) {
    // If error type then convert to string first
    data = Object.prototype.toString.call(data) === '[object Error]' ? data.toString() : data

    data = isPlainObject(data) ? { ...data } : Array.isArray(data) ? [...data] : data

    data = safeJsonStringify(data)

    // Only JSON stringify if data isn't a primitive value
    this.list.push({
      message,
      data,
      level,
      guestId: this.guestId,
      createdAt: date.now().toDbFormat()
    })
  }
}
