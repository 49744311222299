import { DateTime, Settings } from 'luxon'

// All dates must be passed around in UTC. The client will convert to local timezone using setLocale modifiers
Settings.defaultZone = 'utc'

declare module 'luxon/src/datetime' {
  export interface DateTime {
    toDbFormat(): string
  }
}

DateTime.prototype.toDbFormat = function (): string {
  const _self = this as DateTime

  return _self.toFormat('yyyy-LL-dd TT')
}

export const date = DateTime

// Show luxon relative date, but if the date is older than maxDurationHours, show the full date
export function relativeDate(
  dateSQLString: string | undefined | null,
  locale?: string,
  maxDurationHours: number = 24,
  fullFormat: Intl.DateTimeFormatOptions = DateTime.DATETIME_SHORT
) {
  if (!dateSQLString) {
    return null
  }

  // TODO: Waiting on bug fix: https://github.com/drizzle-team/drizzle-orm/issues/2282
  dateSQLString = dateSQLString.replace('T', ' ')

  const date = DateTime.fromSQL(dateSQLString)

  if (Math.abs(date.diffNow().as('hours')) < maxDurationHours) {
    return date.toRelative({ locale })
  }

  return date.toLocaleString(fullFormat)
}
