export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook('vue:error', (error, instance, info) => {
    console.log('Hooked error', error, instance, info)
    nuxtApp.$sentry.captureException(error)
  })

  nuxtApp.hook('app:error', error => {
    console.log('Startup error', error)
    nuxtApp.$sentry.captureException(error)
  })
})
