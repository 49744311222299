<script setup lang="ts">
import type { NuxtError } from '#app'

const { t } = useI18n()

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true
  }
})

const title = props.error?.statusCode === 404 ? 'Page not found' : 'Errors'

useSeoMeta({
  title,
  description: props.error?.message
})

useHead({
  htmlAttrs: {
    lang: 'en'
  }
})

const handleError = () => clearError({ redirect: '/' })

const isHelpModalOpen = ref(false)
</script>

<template>
  <div class="grid h-screen place-content-center bg-white px-4">
    <div class="text-center">
      <h1 class="text-7xl font-black text-gray-200">{{ props.error.statusCode }}</h1>

      <p class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ title }}</p>

      <p class="mt-4 text-gray-500">{{ props.error?.message }}</p>

      <p class="mt-4">{{ t('developersAlerted') }}</p>

      <UButton class="mt-6" @click="handleError">{{ t('goBackHome') }}</UButton>

      <div class="mt-6">
        <Anchor leadingIcon="i-heroicons-question-mark-circle" @click="isHelpModalOpen = true">
          {{ t('getMoreHelp') }}
        </Anchor>
      </div>
    </div>

    <HelpModal v-model="isHelpModalOpen" />
    <UNotifications />
  </div>
</template>
