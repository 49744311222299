import { useApi } from '~/composables/api/useApi'
import type { AuthForgotPassword } from '~/models/auth/forgot-password.model'
import { type AuthLogin } from '~/models/auth/login.model'
import { type Profile } from '~/models/auth/profile.model'
import { type AuthResetPassword } from '~/models/auth/reset-password.model'
import type { AuthUpdatePassword } from '~/models/auth/update-password.model'
import {
  type AuthRefreshTokenSelect,
  type UserSelect,
  type UserWithOrganisationRoles
} from '~/server/database/schema'

export function useAuthService() {
  const { apiStatus, api } = useApi()
  const authStore = useAuthStore()
  const { $log, $sentry } = useNuxtApp()
  const config = useRuntimeConfig()
  const { tenantId } = useTenant()

  async function register(formData: Profile): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof register>>('/auth/v1/register', {
      method: 'POST',
      body: formData
    })

    $log.event('Auth: registered')

    authStore.setAccessToken(accessToken)
    authStore.setRefreshToken(refreshToken)

    return {
      accessToken,
      refreshToken,
      user
    }
  }

  async function loginWithPassword(formData: AuthLogin): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof loginWithPassword>>(
      '/auth/v1/login/password',
      {
        method: 'POST',
        body: formData
      }
    )

    $log.event('Auth: logged in')

    authStore.setAccessToken(accessToken)
    authStore.setRefreshToken(refreshToken)

    await fetchProfile()

    // If we previously logged in with an oauth provider, clear it
    authStore.setLastUsedOauthProvider(null)

    return {
      accessToken,
      refreshToken,
      user
    }
  }

  async function loginWithOauthProvider(
    provider: string,
    providerAccessToken: string
  ): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof loginWithOauthProvider>>(
      '/auth/v1/login/oauth',
      {
        method: 'POST',
        body: {
          provider,
          providerAccessToken
        }
      }
    )

    $log.event('Auth: logged in with oauth provider', provider)

    authStore.setAccessToken(accessToken)
    authStore.setRefreshToken(refreshToken)

    await fetchProfile()

    return {
      accessToken,
      refreshToken,
      user
    }
  }

  async function logout(returnToPath?: string) {
    const refreshToken = authStore.getRefreshToken()

    $log.event('Auth: logged out')

    // Revoke our refresh token from the db
    if (refreshToken) {
      api('/auth/v1/logout', {
        method: 'POST',
        body: {
          refreshToken: authStore.getRefreshToken()
        }
      })
    }

    authStore.clearTokens()

    $log.clearIdentity()

    let url = '/auth/login'

    if (returnToPath) {
      url += `?redirect=${returnToPath}`
    }

    await navigateTo(url)
  }

  async function forgotPassword(formData: AuthForgotPassword) {
    $log.event('Auth: forgot password')

    return await api('/auth/v1/forgot-password', {
      method: 'POST',
      body: formData
    })
  }

  async function fetchProfile() {
    try {
      const user: UserWithOrganisationRoles = await api('/profile/v1', {
        method: 'GET'
      })

      if (!user?.id) {
        throw new Error('No user found in profile response')
      }

      authStore.setCurrentUser(user)

      $log.setIdentity(user)

      $sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.fullName
      })

      return user
    } catch (error) {
      $log.error('Failed to fetch profile', error)

      logout()

      return null
    }
  }

  async function updateProfile(profile: Profile) {
    try {
      const user: UserWithOrganisationRoles = await api('/profile/v1', {
        method: 'PUT',
        body: profile
      })

      await fetchProfile()

      return user
    } catch (error) {
      $log.error('Failed to update profile', error)

      return null
    }
  }

  async function updatePassword(formData: AuthUpdatePassword) {
    return await api('/auth/v1/password', {
      method: 'PUT',
      body: formData
    })
  }

  async function refreshTokens() {
    const refreshToken = authStore.getRefreshToken()

    if (refreshToken) {
      $log.debug('Auth: Refreshing token')

      await $fetch('/auth/v1/refresh', {
        baseURL: config.public.platformApiBaseUrl,
        method: 'POST',
        body: {
          refreshToken
        },
        headers: {
          'Tenant-Id': tenantId
        },

        onResponse({ response }) {
          extractTokensFromResponse(response)
        },

        async onResponseError({ response }) {
          if (response.status === 401) {
            $log.debug('Auth: Failed to refresh token')

            // Failed to refresh token, logout and redirect to login page
            await authStore.clearTokens()
          }
        }
      })
    }
  }

  async function resetPassword(formData: AuthResetPassword) {
    const { accessToken, refreshToken } = await api<{
      accessToken: string
      refreshToken: string
    }>('/auth/v1/reset-password', {
      method: 'POST',
      body: formData
    })

    $log.event('Auth: set new password')

    authStore.setAccessToken(accessToken)
    authStore.setRefreshToken(refreshToken)

    return {
      accessToken,
      refreshToken
    }
  }

  async function validateResetPasswordCode(verificationCode: string): Promise<{
    isValid: boolean
  }> {
    try {
      return await api(`/auth/v1/reset-password/${verificationCode}`)
    } catch (error) {
      return {
        isValid: false
      }
    }
  }

  async function impersonateUser(userId: string): Promise<{
    accessToken: string
    refreshToken: string
    user: UserSelect
  }> {
    const { user, accessToken, refreshToken } = await api<ReturnType<typeof impersonateUser>>(
      '/auth/v1/impersonate',
      {
        method: 'POST',
        body: {
          userId
        }
      }
    )

    $log.event('Auth: impersonated user', userId)

    // Keep our admin refresh token so we can easily switch back to admin user
    authStore.preserveAdminRefreshToken()

    authStore.setAccessToken(accessToken)
    authStore.setRefreshToken(refreshToken)

    await fetchProfile()

    return {
      accessToken,
      refreshToken,
      user
    }
  }

  async function returnFromImpersonation() {
    $log.event('Auth: returned from impersonation')

    // Clear the impersonation tokens
    authStore.clearTokens()

    // Restore the admin refresh token
    authStore.restoreAdminRefreshToken()

    await fetchProfile()

    navigateTo(authStore.accountPath)
  }

  async function removeOauth(authId: string) {
    const removeOauthResponse = await api(`/auth/v1/login/${authId}`, {
      method: 'DELETE'
    })

    await fetchProfile()

    return removeOauthResponse
  }

  async function fetchActiveLogins(): Promise<AuthRefreshTokenSelect[]> {
    return await api('/auth/v1/login', {
      method: 'GET'
    })
  }

  async function logoutSessionId(refreshTokenId: string) {
    return await api(`/auth/v1/refresh`, {
      method: 'DELETE',
      body: {
        refreshTokenId
      }
    })
  }

  return {
    apiStatus,
    fetchProfile,
    register,
    loginWithPassword,
    loginWithOauthProvider,
    logout,
    forgotPassword,
    checkAccessTokenLifespan,
    refreshTokens,
    extractTokensFromResponse,
    resetPassword,
    validateResetPasswordCode,
    impersonateUser,
    returnFromImpersonation,
    updateProfile,
    updatePassword,
    removeOauth,
    fetchActiveLogins,
    logoutSessionId
  }
}
