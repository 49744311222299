import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/vue'

import { appVersion } from '~/shared/version'

export default defineNuxtPlugin({
  name: 'sentry',
  setup(nuxtApp) {
    if (import.meta.env.TEST) {
      return
    }

    const {
      public: { errorReportingEnabled, sentry }
    } = useRuntimeConfig()

    if (!sentry.client.dsn) {
      console.error('Sentry DSN not found in environment')
      return
    }

    Sentry.init({
      enabled: errorReportingEnabled,
      app: nuxtApp.vueApp,
      release: appVersion,
      dsn: sentry.client.dsn,
      // debug: true,
      environment: sentry.environment,
      ignoreErrors: [/Network Error/i, /Fetch Error/i]
    })

    Sentry.setTag('app-platform', Capacitor.getPlatform())
    Sentry.setTag('app-native', Capacitor.isNativePlatform())

    return {
      provide: {
        sentry: Sentry
      }
    }
  }
})
