import { decodeJwt } from 'jose'

export function getPayloadFromAccessToken(token: string) {
  if (!token) {
    return null
  }

  return decodeJwt(token)
}

export function hasJwtExpired(token: string) {
  if (!token) {
    return true
  }

  const payload = getPayloadFromAccessToken(token)

  if (!payload || !payload.exp) {
    return true
  }

  return Date.now() >= payload.exp * 1000
}

export function isJwtExpiringInNext5Minutes(token: string) {
  if (!token) {
    return true
  }

  const payload = getPayloadFromAccessToken(token)

  if (!payload || !payload.exp) {
    return true
  }

  const fiveMinutesInMs = 300000

  return Date.now() >= payload.exp * 1000 - fiveMinutesInMs
}
