<script setup lang="ts">
const slots = useSlots()

const props = defineProps({
  to: {
    type: String,
    default: null
  },

  // inline automatically sets underline
  type: {
    type: String,
    default: 'inline',
    validator: (value: string) => ['block', 'inline'].includes(value)
  },

  underline: {
    type: Boolean,
    default: false
  },

  openInNewTab: {
    type: Boolean,
    default: false
  },

  openInAppBrowser: {
    type: Boolean,
    default: false
  },

  isLoading: {
    type: Boolean,
    default: false
  },

  // Overrides leadingIcon
  leadingText: {
    type: String,
    default: null
  },

  leadingIcon: {
    type: String,
    default: null
  },

  trailingText: {
    type: String,
    default: null
  },

  trailingIcon: {
    type: String,
    default: null
  },

  inheritColor: {
    type: Boolean,
    default: false
  },

  exactRouteMatching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click'])

const willOpenInNewTab = computed(
  () => props.openInNewTab || (typeof props.to === 'string' && props.to.includes('https://'))
)

const isDeepLink = computed(
  () =>
    typeof props.to === 'string' &&
    Boolean(props.to) &&
    (props.to.includes('mailto:') || props.to.includes('tel:'))
)

const linkClass = computed(() => {
  return [
    {
      'flex items-center': props.type !== 'inline',
      'inline-flex items-center': props.type === 'inline',
      'text-primary-500 hover:text-primary-900': !props.inheritColor,
      'text-current': props.inheritColor
    },
    'cursor-pointer'
  ]
})

const hasPrefix = computed(() => props.leadingText || props.leadingIcon || slots.prefix)
const hasSuffix = computed(() => props.trailingText || props.trailingIcon || slots.suffix)
</script>

<template>
  <NuxtLink
    :to="props.to"
    :class="linkClass"
    :external="willOpenInNewTab || isDeepLink"
    :target="willOpenInNewTab ? '_blank' : null"
    :exact="props.exactRouteMatching"
    @click.stop="emit('click')"
  >
    <!-- @slot Custom loading content, otherwise a spinner. Loading replaces prefix -->
    <slot v-if="props.isLoading" name="loading">
      <Loader iconOnly />
    </slot>

    <span v-if="hasPrefix" class="mr-1">
      <slot name="prefix">
        <template v-if="props.leadingText">{{ props.leadingText }}</template>

        <Icon v-else-if="props.leadingIcon" data-testid="icon" :name="props.leadingIcon" />
      </slot>
    </span>

    <template v-if="props.underline">
      <span class="inline-flex underline">
        <slot class="underline" />
      </span>
    </template>

    <span v-else>
      <slot />
    </span>

    <span v-if="hasSuffix" class="ml-1">
      <slot name="suffix">
        <template v-if="props.trailingText">{{ props.trailingText }}</template>

        <Icon v-else-if="props.trailingIcon" :name="props.trailingIcon" />
      </slot>
    </span>
  </NuxtLink>
</template>

<style scoped>
a {
  transition: color 0.2s;
}
</style>
