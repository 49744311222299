import { default as profile8aUpeyZ7XzMeta } from "/home/runner/work/saas/saas/src/pages/admin/account/profile.vue?macro=true";
import { default as securityCTlqmYLnDxMeta } from "/home/runner/work/saas/saas/src/pages/admin/account/security.vue?macro=true";
import { default as accountF7MXk7my83Meta } from "/home/runner/work/saas/saas/src/pages/admin/account.vue?macro=true";
import { default as editUr6D38eFPyMeta } from "/home/runner/work/saas/saas/src/pages/admin/customers/[id]/edit.vue?macro=true";
import { default as logsqqizZT57iAMeta } from "/home/runner/work/saas/saas/src/pages/admin/customers/[id]/logs.vue?macro=true";
import { default as overviewLERfZP4TMAMeta } from "/home/runner/work/saas/saas/src/pages/admin/customers/[id]/overview.vue?macro=true";
import { default as replaysL8UM7vs1GqMeta } from "/home/runner/work/saas/saas/src/pages/admin/customers/[id]/replays.vue?macro=true";
import { default as _91id_93sMofHJgx7FMeta } from "/home/runner/work/saas/saas/src/pages/admin/customers/[id].vue?macro=true";
import { default as indexL7fbZL3sWqMeta } from "/home/runner/work/saas/saas/src/pages/admin/customers/index.vue?macro=true";
import { default as indexFMFclqgj8yMeta } from "/home/runner/work/saas/saas/src/pages/admin/index.vue?macro=true";
import { default as brandEaASScd3PaMeta } from "/home/runner/work/saas/saas/src/pages/admin/settings/brand.vue?macro=true";
import { default as domains3FBQNcAF0qMeta } from "/home/runner/work/saas/saas/src/pages/admin/settings/domains.vue?macro=true";
import { default as notificationsbeMIOx7k5kMeta } from "/home/runner/work/saas/saas/src/pages/admin/settings/notifications.vue?macro=true";
import { default as teamX0uHQ4nEJAMeta } from "/home/runner/work/saas/saas/src/pages/admin/settings/team.vue?macro=true";
import { default as settingsq8L3Qnn97uMeta } from "/home/runner/work/saas/saas/src/pages/admin/settings.vue?macro=true";
import { default as forgot_45passwordmjBr3NlymZMeta } from "/home/runner/work/saas/saas/src/pages/auth/forgot-password.vue?macro=true";
import { default as loginCBXoqu3zx6Meta } from "/home/runner/work/saas/saas/src/pages/auth/login.vue?macro=true";
import { default as registerjAq8CtfeLpMeta } from "/home/runner/work/saas/saas/src/pages/auth/register.vue?macro=true";
import { default as _91verificationCode_93YfGILUPUnAMeta } from "/home/runner/work/saas/saas/src/pages/auth/reset-password/[verificationCode].vue?macro=true";
import { default as indexUX1JDFMCsOMeta } from "/home/runner/work/saas/saas/src/pages/index.vue?macro=true";
import { default as indexHZV91tsrDNMeta } from "/home/runner/work/saas/saas/src/pages/platform/index.vue?macro=true";
import { default as indextSWRRIKC6cMeta } from "/home/runner/work/saas/saas/src/pages/staff/index.vue?macro=true";
import { default as toolboxbib8p2lZCeMeta } from "/home/runner/work/saas/saas/src/pages/toolbox.vue?macro=true";
export default [
  {
    name: accountF7MXk7my83Meta?.name ?? "admin-account",
    path: accountF7MXk7my83Meta?.path ?? "/admin/account",
    meta: accountF7MXk7my83Meta || {},
    alias: accountF7MXk7my83Meta?.alias || [],
    redirect: accountF7MXk7my83Meta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/account.vue").then(m => m.default || m),
    children: [
  {
    name: profile8aUpeyZ7XzMeta?.name ?? "admin-account-profile",
    path: profile8aUpeyZ7XzMeta?.path ?? "profile",
    meta: profile8aUpeyZ7XzMeta || {},
    alias: profile8aUpeyZ7XzMeta?.alias || [],
    redirect: profile8aUpeyZ7XzMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/account/profile.vue").then(m => m.default || m)
  },
  {
    name: securityCTlqmYLnDxMeta?.name ?? "admin-account-security",
    path: securityCTlqmYLnDxMeta?.path ?? "security",
    meta: securityCTlqmYLnDxMeta || {},
    alias: securityCTlqmYLnDxMeta?.alias || [],
    redirect: securityCTlqmYLnDxMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93sMofHJgx7FMeta?.name ?? "admin-customers-id",
    path: _91id_93sMofHJgx7FMeta?.path ?? "/admin/customers/:id()",
    meta: _91id_93sMofHJgx7FMeta || {},
    alias: _91id_93sMofHJgx7FMeta?.alias || [],
    redirect: _91id_93sMofHJgx7FMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/customers/[id].vue").then(m => m.default || m),
    children: [
  {
    name: editUr6D38eFPyMeta?.name ?? "admin-customers-id-edit",
    path: editUr6D38eFPyMeta?.path ?? "edit",
    meta: editUr6D38eFPyMeta || {},
    alias: editUr6D38eFPyMeta?.alias || [],
    redirect: editUr6D38eFPyMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/customers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: logsqqizZT57iAMeta?.name ?? "admin-customers-id-logs",
    path: logsqqizZT57iAMeta?.path ?? "logs",
    meta: logsqqizZT57iAMeta || {},
    alias: logsqqizZT57iAMeta?.alias || [],
    redirect: logsqqizZT57iAMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/customers/[id]/logs.vue").then(m => m.default || m)
  },
  {
    name: overviewLERfZP4TMAMeta?.name ?? "admin-customers-id-overview",
    path: overviewLERfZP4TMAMeta?.path ?? "overview",
    meta: overviewLERfZP4TMAMeta || {},
    alias: overviewLERfZP4TMAMeta?.alias || [],
    redirect: overviewLERfZP4TMAMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/customers/[id]/overview.vue").then(m => m.default || m)
  },
  {
    name: replaysL8UM7vs1GqMeta?.name ?? "admin-customers-id-replays",
    path: replaysL8UM7vs1GqMeta?.path ?? "replays",
    meta: replaysL8UM7vs1GqMeta || {},
    alias: replaysL8UM7vs1GqMeta?.alias || [],
    redirect: replaysL8UM7vs1GqMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/customers/[id]/replays.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexL7fbZL3sWqMeta?.name ?? "admin-customers",
    path: indexL7fbZL3sWqMeta?.path ?? "/admin/customers",
    meta: indexL7fbZL3sWqMeta || {},
    alias: indexL7fbZL3sWqMeta?.alias || [],
    redirect: indexL7fbZL3sWqMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFMFclqgj8yMeta?.name ?? "admin",
    path: indexFMFclqgj8yMeta?.path ?? "/admin",
    meta: indexFMFclqgj8yMeta || {},
    alias: indexFMFclqgj8yMeta?.alias || [],
    redirect: indexFMFclqgj8yMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: settingsq8L3Qnn97uMeta?.name ?? "admin-settings",
    path: settingsq8L3Qnn97uMeta?.path ?? "/admin/settings",
    meta: settingsq8L3Qnn97uMeta || {},
    alias: settingsq8L3Qnn97uMeta?.alias || [],
    redirect: settingsq8L3Qnn97uMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/settings.vue").then(m => m.default || m),
    children: [
  {
    name: brandEaASScd3PaMeta?.name ?? "admin-settings-brand",
    path: brandEaASScd3PaMeta?.path ?? "brand",
    meta: brandEaASScd3PaMeta || {},
    alias: brandEaASScd3PaMeta?.alias || [],
    redirect: brandEaASScd3PaMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/settings/brand.vue").then(m => m.default || m)
  },
  {
    name: domains3FBQNcAF0qMeta?.name ?? "admin-settings-domains",
    path: domains3FBQNcAF0qMeta?.path ?? "domains",
    meta: domains3FBQNcAF0qMeta || {},
    alias: domains3FBQNcAF0qMeta?.alias || [],
    redirect: domains3FBQNcAF0qMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/settings/domains.vue").then(m => m.default || m)
  },
  {
    name: notificationsbeMIOx7k5kMeta?.name ?? "admin-settings-notifications",
    path: notificationsbeMIOx7k5kMeta?.path ?? "notifications",
    meta: notificationsbeMIOx7k5kMeta || {},
    alias: notificationsbeMIOx7k5kMeta?.alias || [],
    redirect: notificationsbeMIOx7k5kMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: teamX0uHQ4nEJAMeta?.name ?? "admin-settings-team",
    path: teamX0uHQ4nEJAMeta?.path ?? "team",
    meta: teamX0uHQ4nEJAMeta || {},
    alias: teamX0uHQ4nEJAMeta?.alias || [],
    redirect: teamX0uHQ4nEJAMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/admin/settings/team.vue").then(m => m.default || m)
  }
]
  },
  {
    name: forgot_45passwordmjBr3NlymZMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordmjBr3NlymZMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordmjBr3NlymZMeta || {},
    alias: forgot_45passwordmjBr3NlymZMeta?.alias || [],
    redirect: forgot_45passwordmjBr3NlymZMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginCBXoqu3zx6Meta?.name ?? "auth-login",
    path: loginCBXoqu3zx6Meta?.path ?? "/auth/login",
    meta: loginCBXoqu3zx6Meta || {},
    alias: loginCBXoqu3zx6Meta?.alias || [],
    redirect: loginCBXoqu3zx6Meta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registerjAq8CtfeLpMeta?.name ?? "auth-register",
    path: registerjAq8CtfeLpMeta?.path ?? "/auth/register",
    meta: registerjAq8CtfeLpMeta || {},
    alias: registerjAq8CtfeLpMeta?.alias || [],
    redirect: registerjAq8CtfeLpMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91verificationCode_93YfGILUPUnAMeta?.name ?? "auth-reset-password-verificationCode",
    path: _91verificationCode_93YfGILUPUnAMeta?.path ?? "/auth/reset-password/:verificationCode()",
    meta: _91verificationCode_93YfGILUPUnAMeta || {},
    alias: _91verificationCode_93YfGILUPUnAMeta?.alias || [],
    redirect: _91verificationCode_93YfGILUPUnAMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/auth/reset-password/[verificationCode].vue").then(m => m.default || m)
  },
  {
    name: indexUX1JDFMCsOMeta?.name ?? "index",
    path: indexUX1JDFMCsOMeta?.path ?? "/",
    meta: indexUX1JDFMCsOMeta || {},
    alias: indexUX1JDFMCsOMeta?.alias || [],
    redirect: indexUX1JDFMCsOMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHZV91tsrDNMeta?.name ?? "platform",
    path: indexHZV91tsrDNMeta?.path ?? "/platform",
    meta: indexHZV91tsrDNMeta || {},
    alias: indexHZV91tsrDNMeta?.alias || [],
    redirect: indexHZV91tsrDNMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/platform/index.vue").then(m => m.default || m)
  },
  {
    name: indextSWRRIKC6cMeta?.name ?? "staff",
    path: indextSWRRIKC6cMeta?.path ?? "/staff",
    meta: indextSWRRIKC6cMeta || {},
    alias: indextSWRRIKC6cMeta?.alias || [],
    redirect: indextSWRRIKC6cMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/staff/index.vue").then(m => m.default || m)
  },
  {
    name: toolboxbib8p2lZCeMeta?.name ?? "toolbox",
    path: toolboxbib8p2lZCeMeta?.path ?? "/toolbox",
    meta: toolboxbib8p2lZCeMeta || {},
    alias: toolboxbib8p2lZCeMeta?.alias || [],
    redirect: toolboxbib8p2lZCeMeta?.redirect,
    component: () => import("/home/runner/work/saas/saas/src/pages/toolbox.vue").then(m => m.default || m)
  }
]