import { Capacitor } from '@capacitor/core'
import { posthog } from 'posthog-js'

import { appVersion } from '~/shared/version'

export default defineNuxtPlugin({
  name: 'analytics',
  setup() {
    if (import.meta.env.TEST) {
      return
    }

    const config = useRuntimeConfig()
    const router = useRouter()

    if (config.public.analyticsReportingEnabled) {
      posthog.init(config.public.posthog.dsn, {
        capture_pageview: false,
        capture_pageleave: true,
        api_host: config.public.posthog.apiHost,
        disable_session_recording: true,
        advanced_disable_decide: true,
        advanced_disable_feature_flags: true,
        advanced_disable_feature_flags_on_first_load: true,
        enable_recording_console_log: false,
        autocapture: false
      })

      posthog.register({
        environment: config.public.appEnvironment,
        countryCode: config.public.appCountryCode,
        release: appVersion,
        platform: Capacitor.getPlatform(),
        native: Capacitor.isNativePlatform()
      })

      router.afterEach(to => {
        posthog.capture('$pageview', {
          $current_url: to.fullPath
        })
      })
    }

    return {
      provide: {
        analytics: posthog
      }
    }
  }
})
