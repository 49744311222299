import revive_payload_client_4sVQNw7RlN from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/saas/saas/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/saas/saas/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/saas/saas/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/saas/saas/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/saas/saas/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/runner/work/saas/saas/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_LDumGYo2KH from "/home/runner/work/saas/saas/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/home/runner/work/saas/saas/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/home/runner/work/saas/saas/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/home/runner/work/saas/saas/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_1aypKNZ222 from "/home/runner/work/saas/saas/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "/home/runner/work/saas/saas/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "/home/runner/work/saas/saas/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import _01_analytics_3XGn67xQPO from "/home/runner/work/saas/saas/src/plugins/01.analytics.ts";
import _02_sentry_75gnQo6KoD from "/home/runner/work/saas/saas/src/plugins/02.sentry.ts";
import _03_logger_YihXa1GKqD from "/home/runner/work/saas/saas/src/plugins/03.logger.ts";
import _03_network_3t28z6MUUo from "/home/runner/work/saas/saas/src/plugins/03.network.ts";
import errorHandler_RhZ3LQWM9V from "/home/runner/work/saas/saas/src/plugins/errorHandler.ts";
import formValidation_RfZ7A8wZTU from "/home/runner/work/saas/saas/src/plugins/formValidation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod,
  _01_analytics_3XGn67xQPO,
  _02_sentry_75gnQo6KoD,
  _03_logger_YihXa1GKqD,
  _03_network_3t28z6MUUo,
  errorHandler_RhZ3LQWM9V,
  formValidation_RfZ7A8wZTU
]