import validate from "/home/runner/work/saas/saas/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45tenant_45global from "/home/runner/work/saas/saas/src/middleware/01.tenant.global.ts";
import _02_45auth_45global from "/home/runner/work/saas/saas/src/middleware/02.auth.global.ts";
import manifest_45route_45rule from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45tenant_45global,
  _02_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}