import { type TenantDomains } from '~/models/tenant/tenant-domains.model'
import { type TenantSettings } from '~/models/tenant/tenant-settings.model'
import { type TenantSelect } from '~/server/database/schema/tenant'
import { type TenantDomainSelect } from '~/server/database/schema/tenant-domain'

export function useTenantService() {
  const { apiStatus, api } = useApi()
  const url = useRequestURL()
  const currentHostname = url.hostname

  async function fetchTenant(tenantId: string) {
    return await api<TenantSelect>(`/tenant/v1/${tenantId}`)
  }

  async function fetchTenantByCurrentHostname(hostname?: string) {
    return await api<TenantSelect>(`/tenant/v1/domain/${hostname || currentHostname}`)
  }

  async function updateSettings(data: TenantSettings) {
    const { tenantId } = useTenant()

    return await api<TenantSelect>(`/tenant/v1/${tenantId}`, {
      method: 'PUT',
      body: data
    })
  }

  async function fetchTenantDomains(tenantId: string) {
    return await api<TenantDomainSelect[]>(`/tenant/v1/${tenantId}/domain`)
  }

  async function updateDomains(data: TenantDomains[]) {
    const { tenantId } = useTenant()

    return await api<TenantDomainSelect>(`/tenant/v1/${tenantId}/domain`, {
      method: 'PUT',
      body: data
    })
  }

  return {
    apiStatus,
    updateSettings,
    fetchTenant,
    fetchTenantByCurrentHostname,
    fetchTenantDomains,
    updateDomains
  }
}
