import { breakpointsTailwind, useBreakpoints as useTailwindBreakpoints } from '@vueuse/core'

export function useBreakpoints() {
  const breakpoints = useTailwindBreakpoints(breakpointsTailwind)
  const activeBreakpoint = breakpoints.active()

  return {
    activeBreakpoint,
    breakpoints
  }
}
