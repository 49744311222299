export default defineAppConfig({
  ui: {
    icons: {
      dynamic: true
    },

    primary: 'blue',
    gray: 'cool',

    button: {
      default: {
        size: 'lg'
      }
    },
    input: {
      default: {
        size: 'lg',
        loadingIcon: 'i-line-md-loading-twotone-loop'
      }
    },
    select: {
      default: {
        size: 'lg'
      }
    },
    alert: {
      wrapper: 'overflow-visible'
    },
    label: {
      base: 'font-bold'
    },
    selectMenu: {
      default: {
        size: 'lg'
      }
    },
    card: {
      header: {
        base: 'flex flex-wrap items-center justify-between'
      }
    },
    notifications: {
      position: 'bottom-auto top-0'
    },
    formGroup: {
      label: {
        wrapper: 'font-bold',
        class: 'font-bold'
      }
    }
  }
})
