import { ref } from 'vue'

export type ApiStatus = {
  isLoading: boolean
  response: {
    code: number | null
    status: string | null
    message: string | null
    error: string | null
  }
}

// Used to help show loading status, response status, and error messages in a consistent way across all API calls
export function useApiStatus() {
  return ref<ApiStatus>({
    isLoading: false,
    response: {
      code: null,
      status: null,
      message: null,
      error: null
    }
  })
}
