export default defineNuxtRouteMiddleware(async to => {
  const authStore = useAuthStore()
  const { checkAccessTokenLifespan, fetchProfile } = useAuthService()

  await checkAccessTokenLifespan()

  if (authStore.isLoggedIn && !authStore.currentUser?.id && !to.path.startsWith('/auth')) {
    try {
      await fetchProfile()
    } catch (error) {
      return navigateTo(`/auth/login?redirect=${to.path}`)
    }
  }

  // Lost guest user
  if (
    (to.path.startsWith('/admin') || to.path.startsWith('/staff') || to.path.startsWith('/platform')) &&
    !authStore.isLoggedIn
  ) {
    console.log('Lost guest user')
    return navigateTo(`/auth/login?redirect=${to.path}`)
  }

  // console.log('authStore.isStaffOrganisation', authStore.isStaffOrganisation)
  // console.log('authStore.isTenantOrganisation', authStore.isTenantOrganisation)

  // Lost staff member
  if (authStore.isStaffOrganisation && (to.path.startsWith('/admin') || to.path.startsWith('/platform'))) {
    console.log('Lost staff member')
    return navigateTo('/staff')
  }

  // Lost app admin
  if (authStore.isTenantOrganisation && (to.path.startsWith('/staff') || to.path.startsWith('/platform'))) {
    console.log('Lost app admin')
    return navigateTo('/admin')
  }

  // Lost customer
  if (
    !authStore.isTenantOrganisation &&
    !authStore.isStaffOrganisation &&
    (to.path.startsWith('/staff') || to.path.startsWith('/admin'))
  ) {
    console.log('Lost customer')
    return navigateTo('/platform')
  }
})
