
// @ts-nocheck


export const localeCodes =  [
  "en-GB",
  "fr-FR",
  "de-DE"
]

export const localeLoaders = {
  "en-GB": [{ key: "../src/locales/en-GB.json", load: () => import("../src/locales/en-GB.json" /* webpackChunkName: "locale__home_runner_work_saas_saas_src_locales_en_GB_json" */), cache: true }],
  "fr-FR": [{ key: "../src/locales/fr-FR.json", load: () => import("../src/locales/fr-FR.json" /* webpackChunkName: "locale__home_runner_work_saas_saas_src_locales_fr_FR_json" */), cache: true }],
  "de-DE": [{ key: "../src/locales/de-DE.json", load: () => import("../src/locales/de-DE.json" /* webpackChunkName: "locale__home_runner_work_saas_saas_src_locales_de_DE_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./src/server/utils/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en-GB",
      "name": "British English",
      "iso": "en-GB",
      "isCatchallLocale": true,
      "files": [
        "locales/en-GB.json"
      ]
    },
    {
      "name": "French",
      "code": "fr-FR",
      "iso": "fr-FR",
      "files": [
        "locales/fr-FR.json"
      ]
    },
    {
      "name": "German",
      "code": "de-DE",
      "iso": "de-DE",
      "files": [
        "locales/de-DE.json"
      ]
    }
  ],
  "defaultLocale": "en-GB",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "locale",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "all",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en-GB",
    "name": "British English",
    "iso": "en-GB",
    "isCatchallLocale": true,
    "files": [
      {
        "path": "locales/en-GB.json"
      }
    ]
  },
  {
    "name": "French",
    "code": "fr-FR",
    "iso": "fr-FR",
    "files": [
      {
        "path": "locales/fr-FR.json"
      }
    ]
  },
  {
    "name": "German",
    "code": "de-DE",
    "iso": "de-DE",
    "files": [
      {
        "path": "locales/de-DE.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
