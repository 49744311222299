export default defineNuxtRouteMiddleware(async () => {
  const { activeTenant, setActiveTenant } = useTenant()
  const { fetchTenantByCurrentHostname } = useTenantService()

  const { $log } = useNuxtApp()

  if (!activeTenant.value) {
    $log.debug('No tenant yet, fetching from API')

    try {
      const tenantWithDomain = await fetchTenantByCurrentHostname()

      setActiveTenant(tenantWithDomain.tenant)
    } catch (error: any) {
      $log.error('Error fetching tenant', error)

      if (error.statusCode === 404) {
        throw createError({
          statusCode: 500,
          statusMessage: 'The current domain is not associated with this platform'
        })
      } else {
        return navigateTo('/maintenance')
      }
    }
  }

  console.log('middleware tenant', activeTenant.value)
})
